<template>
  <ul
    v-if="questions && questions.length"
    class="list-stacked list-stacked--large list-stacked--divided"
  >
    <li v-for="(question, i) in questions" :key="i">
      <Question :question="question" />
    </li>
  </ul>
  <div v-else-if="questions" class="text-muted">
    {{ gettext('Cette thématique ne contient aucune information.') }}
  </div>
  <div v-else class="text-muted">{{ gettext('Chargement…') }}</div>
</template>

<script>
import Question from '@/components/faq/Question.vue';

export default {
  components: {
    Question,
  },

  props: {
    questions: {
      type: Array,
      default: null,
    },
  },
};
</script>

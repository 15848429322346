<template>
  <div class="faq-question">
    <h4 class="h5 mrgv0">
      <a href="#" class="media" @click="toggleCollapse">
        <div class="media__right">
          <Icon :id="isOpen ? 'minus' : 'plus'" class="icon--100" />
        </div>
        <div class="media__body">{{ question.title }}</div>
      </a>
    </h4>
    <transition name="fade">
      <div v-if="isOpen">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="wysiwyg pdgt-" v-html="question.answer_text"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleCollapse(e) {
      e.preventDefault();
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

import { createApp } from 'vue';
import axios from 'axios';

import Faq from '@/components/Faq.vue';
import Icon from '@/components/ui/Icon.vue';

// eslint-disable-next-line max-params
function faq(element, categories, title, subtitle, faqUrl) {
  const initialProps = { element, categories, title, subtitle };

  if (!categories) {
    axios.get(faqUrl).then((payload) => {
      Object.assign(initialProps, payload.data);
    });
  }

  const app = createApp(Faq, initialProps);

  app.component('Icon', Icon);

  app.config.globalProperties.ENP = window.ENP;
  app.config.globalProperties.gettext = window.gettext;
  app.config.globalProperties.interpolate = window.interpolate;

  app.mount(element);
}

window.ENP = window.ENP || {};
Object.assign(window.ENP, {
  faq,
});

<template>
  <div class="faq-category">
    <h3 class="h4 mrgv0">
      <a
        href="#"
        class="faq-category__link media"
        @click.prevent="toggleCollapse"
      >
        <div class="media__right">
          <Icon :id="isOpen ? 'chevron-up' : 'chevron-down'" class="icon--75" />
        </div>
        <div class="media__body">{{ category.name }}</div>
      </a>
    </h3>
    <transition name="fade">
      <div v-if="isOpen" class="pdgv">
        <Questions :questions="questions" />
      </div>
    </transition>
  </div>
</template>

<script>
import Questions from '@/components/faq/Questions.vue';

export default {
  components: {
    Questions,
  },

  props: {
    category: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) this.$emit('open');
    },
  },
};
</script>

<template>
  <div v-if="layout === 'desktop'" class="grid grid--multiline">
    <div class="grid__item w-1/3">
      <h3 class="faq-title mrgt0">{{ title }}&nbsp;</h3>
      <hr />
      <ul
        class="list-stacked list-stacked--large list-stacked--divided faq-categories"
      >
        <li v-for="(category, i) in categories" :key="i">
          <a
            href="#"
            :class="{
              'text-bold': category.id == currentCategory.id,
            }"
            @click.prevent="showCategory(category)"
          >
            {{ category.name }}
          </a>
        </li>
      </ul>
    </div>
    <div class="grid__item w-2/3">
      <h3 class="faq-title mrgt0">{{ subtitle }}&nbsp;</h3>
      <hr />
      <Questions :questions="questions[currentCategory.id]" />
    </div>
  </div>
  <div v-else>
    <h3 class="faq-title mrgt0">{{ title }}&nbsp;</h3>
    <ul class="list-stacked list-stacked--small">
      <li v-for="(category, i) in categories" :key="i">
        <Category
          :category="category"
          :questions="questions[category.id]"
          @open="showCategory(category)"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import axios from 'axios';

import Category from '@/components/faq/Category.vue';
import Questions from '@/components/faq/Questions.vue';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps({
  categories: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
});

const layout = ref('desktop');
const questions = ref({});
const currentCategory = ref(props.categories[0]);

const handleResize = () => {
  layout.value = matchMedia('(min-width: 641px)').matches
    ? 'desktop'
    : 'mobile';
};

const showCategory = (category, e) => {
  if (e) {
    e.preventDefault();
  }

  const { id, url } = category;

  currentCategory.value = category;

  if (!questions.value[id]) {
    axios.get(url).then((payload) => {
      const newQuestions = Object.assign({}, questions.value);
      newQuestions[id] = payload.data;

      questions.value = newQuestions;
    });
  }
};

onMounted(() => {
  handleResize();
  showCategory(currentCategory.value);
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>
